import { FaArrowRight } from "react-icons/fa";
import "./ContactItem.css";

const ContactItem = (props) => {
  return (
    <div className="mt-3" style={{ minWidth: "400px" }}>
      <div
        className="col"
        style={{
          backgroundColor: "#ffc18e",
          borderRadius: "0.75rem",
          padding: "1rem",
        }}
      >
        <h3
          style={{
            backgroundColor: "transparent",
            fontSize: "1.25rem",
            color: "white",
            fontWeight: "600",
            padding: "1rem 0 0 0",
            textAlign: "center",
          }}
        >
          {props.icon}
          <br></br>
          {props.title}
        </h3>

        <p
          style={{
            backgroundColor: "transparent",
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          {props.content}
        </p>
        <a
          style={{
            backgroundColor: "transparent",
            paddingBottom: "1rem",
            fontSize: "0.875rem",
            textDecoration: "none",
            color: "white",
          }}
          href={props.link}
          target="_blank"
        >
          Write Me
          <i
            className="arrow"
            style={{ backgroundColor: "transparent", color: "white" }}
          >
            <FaArrowRight
              color="white"
              style={{
                backgroundColor: "transparent",
                marginLeft: "5px",
                color: "white",
                fill: "white",
              }}
            />
          </i>
        </a>
      </div>
    </div>
  );
};
export default ContactItem;
