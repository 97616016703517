import { Button } from "react-bootstrap";
import "./Footer.css";
import { BsLinkedin } from "react-icons/bs";
// import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content ">
        <div
          className="row"
          style={{
            padding: "3rem",
            backgroundColor: "#403152",
          }}
        >
          <div
            className="col-6"
            style={{ textAlign: "start", backgroundColor: "#403152" }}
          >
            <h3
              style={{
                backgroundColor: "transparent",
                color: "hsl(219, 48%, 8%)",
                fontWeight: "bold",
              }}
            >
              Yohana Novita
            </h3>
            <p>alamat1</p>
            <p>alamat2</p>
            <p>alamat3</p>
          </div>
          <div
            className="col-6"
            style={{ textAlign: "end", backgroundColor: "#403152" }}
          >
            <div
              className="row mb-2"
              style={{ background: "transparent", justifyContent: "end" }}
            >
              <a href="/contact" style={{ background: "transparent" }}>
                <Button
                  style={{
                    backgroundColor: "#ffc18e",
                    fontSize: "1.15rem",
                    padding: "10px",
                    width: "fit-content",
                  }}
                >
                  Get In Touch
                </Button>
              </a>
            </div>
            <BsLinkedin
              style={{
                fill: "#0177b5",
                borderRadius: "50%",
                // backgroundColor: "transparent",
              }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
