import { Button, Accordion } from "react-bootstrap";
import SkillItem from "../component/Skill/SkillItem";
import SkillItemWithIcon from "../component/Skill/SkillItemWithIcon";
import Msword from "../assets/msword.png";
import Ppt from "../assets/ppt.png";
import Excel from "../assets/excel.png";
import Capcut from "../assets/capcut.png";
import Vn from "../assets/vn.png";
import Inshot from "../assets/inshot.png";
import Picsart from "../assets/picstart.png";
import Snapseed from "../assets/snapsheet.png";
import Vsco from "../assets/vsco.png";
import Canva from "../assets/canva.png";
import I1 from "../assets/i1.jpg";
import I2 from "../assets/i2.jpg";
import I3 from "../assets/i3.jpg";
const Skill = () => {
  return (
    <>
      <div className="container mt-2">
        <div
          className="row"
          style={{ textAlign: "start", fontWeight: "lighter" }}
        >
          <Button style={{ width: "25%", backgroundColor: "#403152" }}></Button>
          <p>My Skills</p>
        </div>
        <div className="mt-3" style={{ textAlign: "start" }}>
          <div className="row">
            <div className="col-2">
              <p>Soft Skills</p>
            </div>
            <div
              className="col-3"
              style={{
                fontSize: "0.5rem",
                backgroundColor: "#ffc18e",
                borderRadius: "0.75rem",
                padding: "0.75rem",
                marginLeft: "2rem",
              }}
            >
              <SkillItem skill="Communicative" />
              <SkillItem skill="Leadership" />
              <SkillItem skill="Teamwork" />
              <SkillItem skill="Collaborative" />
              <SkillItem skill="Discipline" />
              <SkillItem skill="Time Management" />
              <SkillItem skill="Critical Thinking" />
            </div>
            <div
              className="col-3"
              style={{
                fontSize: "0.5rem",
                backgroundColor: "#ffc18e",
                borderRadius: "0.75rem",
                padding: "0.75rem",
                marginLeft: "1rem",
              }}
            >
              <SkillItem skill="Responsibility" />
              <SkillItem skill="Detail Oriented" />
              <SkillItem skill="Creative" />
              <SkillItem skill="Innovative" />
              <SkillItem skill="Public Speaking" />
              <SkillItem skill="Networking" />
              <SkillItem skill="Service Oriented" />
            </div>
            <div
              className="col-3"
              style={{
                fontSize: "0.5rem",
                backgroundColor: "#ffc18e",
                borderRadius: "0.75rem",
                padding: "0.75rem",
                marginLeft: "1rem",
              }}
            >
              <SkillItem skill="Content Writing" />
              <SkillItem skill="Content Creation" />
              <SkillItem skill="Content Planning" />
              <SkillItem skill="Event Coordination" />
              <SkillItem skill="Problem Solving" />
              <SkillItem skill="Well Organized" />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-2">
              <p>Tools</p>
            </div>
            <div
              className="col-3"
              style={{
                fontSize: "0.5rem",
                backgroundColor: "#ffc18e",
                borderRadius: "0.75rem",
                padding: "0.75rem",
                marginLeft: "2rem",
              }}
            >
              <p style={{ textAlign: "center", backgroundColor: "#ffc18e" }}>
                Microsoft
              </p>
              <SkillItemWithIcon skill="Ms Word" icon={Msword} />
              <SkillItemWithIcon skill="Ms Excel" icon={Excel} />
              <SkillItemWithIcon skill="Ms PowerPoint" icon={Ppt} />
            </div>
            <div
              className="col-3"
              style={{
                fontSize: "0.5rem",
                backgroundColor: "#ffc18e",
                borderRadius: "0.75rem",
                padding: "0.75rem",
                marginLeft: "1rem",
              }}
            >
              <p style={{ textAlign: "center", backgroundColor: "#ffc18e" }}>
                Video Editor
              </p>
              <SkillItemWithIcon skill="Capcut" icon={Capcut} />
              <SkillItemWithIcon skill="VN" icon={Vn} />
              <SkillItemWithIcon skill="Inshot" icon={Inshot} />
            </div>
            <div
              className="col-3"
              style={{
                fontSize: "0.5rem",
                backgroundColor: "#ffc18e",
                borderRadius: "0.75rem",
                padding: "0.75rem",
                marginLeft: "1rem",
              }}
            >
              <p style={{ textAlign: "center", backgroundColor: "#ffc18e" }}>
                Photo Editor
              </p>
              <SkillItemWithIcon skill="Picsart" icon={Picsart} />
              <SkillItemWithIcon skill="Canva" icon={Canva} />
              <SkillItemWithIcon skill="Snapseed" icon={Snapseed} />
              <SkillItemWithIcon skill="VSCO" icon={Vsco} />
            </div>
          </div>
          <hr />
        </div>
        <div className="row">
          <Accordion>
            <Accordion.Header>Result</Accordion.Header>
            <Accordion.Body>
              <img
                src={I1}
                alt="i1"
                style={{ width: "25%", marginLeft: "1rem" }}
              ></img>
              <img
                src={I2}
                alt="i2"
                style={{ width: "25%", marginLeft: "1rem" }}
              ></img>
              <img
                src={I3}
                alt="i3"
                style={{ width: "25%", marginLeft: "1rem" }}
              ></img>
            </Accordion.Body>
          </Accordion>
        </div>
        <hr />
      </div>
    </>
  );
};

export default Skill;
