import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavigationBar from "./component/Navbar/Navbar";
import Footer from "./component/Footer/Footer";
import Home from "./page/Home";
import About from "./page/About";
import Contact from "./page/Contact";
import Skill from "./page/Skill";

function App() {
  return (
    <div className="App">
      <NavigationBar className="App-header" />
      <div className="body">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/skills" element={<Skill />} />
          </Routes>
        </Router>
      </div>
      <div className="foot" style={{ backgroundColor: "#403152" }}>
        <Footer style={{ backgroundColor: "#403152" }} />,
      </div>
    </div>
  );
}

export default App;
