import foto from "../assets/home.jpg";
import Tilt from "react-parallax-tilt";
const Home = () => {
  return (
    <>
      <div className="row mt-3">
        <Tilt
          style={{
            maxWidth: "500px",
            width: "100%",
            height: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "50%",
            clipPath: "circle()",
          }}
        >
          <img
            src={foto}
            style={{
              maxWidth: "500px",
              width: "100%",
              height: "100%",
            }}
          ></img>
        </Tilt>
      </div>
      <div className="row mt-3">
        <h2>Yohana Novita</h2>
      </div>
      <div className="row mt-1">
        <h5>slogan/bidang pekerjaan?</h5>
      </div>
      <div className="row mt-5 mb-3">
        <h4>Content Singkat??</h4>
        <p>Content Singkat??</p>
      </div>
    </>
  );
};

export default Home;
