import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaCaretDown } from "react-icons/fa";
import "./Navbar.css";
import { useEffect, useState } from "react";
import { BsLinkedin } from "react-icons/bs";

const NavigationBar = () => {
  const [themeDark, setThemeDark] = useState(true);

  const changeTheme = () => {
    setThemeDark(!themeDark);
    if (!themeDark) {
      setTextColor(darkColor);
    } else {
      setTextColor(normalColor);
    }
  };
  // darktheme pallete
  const darkColor = "#ffffff";
  const bgDarkcolor = "hsl(219, 48%, 8%)";

  // normaltheme pallete
  const normalColor = "#ffc18e";
  const [textColor, setTextColor] = useState(darkColor);
  return (
    <>
      <Navbar
        id="nav"
        expand="lg"
        className="shadow p-3"
        style={{
          padding: "20px",
          position: "sticky",
          top: "0",
          zIndex: "1",
          width: "100%",
          backgroundColor: "#ffc18e",
          color: "#ffffff",
        }}
      >
        <Container
          style={{
            backgroundColor: "#ffc18e",
          }}
        >
          <Navbar.Brand
            href="/"
            style={{
              color: textColor,
              fontWeight: "500",
              backgroundColor: "#ffc18e",
            }}
          >
            Yohana Novita
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{
              backgroundColor: "#ffc18e",
            }}
          >
            <Nav
              className="ml-auto "
              style={{
                marginLeft: "auto",
                color: textColor,
                fontSize: "0.875rem",
                backgroundColor: "#ffc18e",
              }}
              // defaultActiveKey="#home"
              // activeKey={""}
            >
              <Nav.Link id="nav-home" href="/" style={{ color: "#ffffff" }}>
                Home
              </Nav.Link>
              <Nav.Link
                id="nav-about"
                href="/about"
                style={{ color: "#ffffff" }}
              >
                About
              </Nav.Link>
              <Nav.Link
                id="nav-skills"
                href="/skills"
                style={{ color: "#ffffff" }}
              >
                Skills
              </Nav.Link>

              <Nav.Link
                id="nav-contact"
                href="/contact"
                style={{ color: "#ffffff" }}
              >
                Contact
              </Nav.Link>
              <Nav.Link
                id="nav-contact"
                href="/contact"
                style={{
                  color: "#ffffff",
                  borderRadius: "50%",
                }}
              >
                <BsLinkedin
                  style={{
                    fill: "#0177b5",
                    borderRadius: "50%",
                  }}
                />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
