import { FaTrophy } from "react-icons/fa";

const SkillItemWithIcon = (props) => {
  return (
    <div
      style={{
        display: "flex",
        columnGap: "0.5rem",
        backgroundColor: "transparent",
      }}
    >
      <img
        src={props.icon}
        alt="icon"
        style={{
          backgroundColor: "transparent",
          height: "1.25rem",
          borderRadius: "5px",
        }}
      />
      <div style={{ backgroundColor: "transparent" }}>
        <p
          style={{
            fontSize: "1.25rem",
            fontWeight: "500",
            lineHeight: "1.25rem",
            marginBottom: "1rem",
            backgroundColor: "transparent",
          }}
        >
          {props.skill}
        </p>
      </div>
    </div>
  );
};
export default SkillItemWithIcon;
