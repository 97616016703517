import foto from "../assets/home.jpg";
import { useState, useEffect } from "react";

const About = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  return (
    <>
      <div className="row">
        <div
          className="col"
          style={{
            position: "relative",
            backgroundColor: "#9f9f9f",
          }}
        >
          <div style={{ backgroundColor: "#9f9f9f" }}>
            {windowSize.width > 640 ? (
              <img
                src={foto}
                alt="max"
                style={{
                  marginLeft: "25%",
                  width: "100%",
                  minWidth: "820px",
                  filter: "brightness(70%)",
                  maxWidth: "1000px",
                }}
              ></img>
            ) : (
              <img
                src={foto}
                alt="min"
                style={{
                  width: "100%",
                  minWidth: "820px",
                  filter: "brightness(70%)",
                }}
              ></img>
            )}
          </div>
          <div className="container">
            <div
              className="col-5"
              style={{
                position: "absolute",
                top: "10%",
                textAlign: "justify",
                backgroundColor: "transparent",
                filter: "brightness(100%)",
              }}
            >
              {windowSize.width > 762 ? (
                <p
                  style={{
                    color: "white",
                    fontWeight: "400",
                    backgroundColor: "transparent",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  I'm Yohana Novita Trisnawati, currently studying at Gadjah
                  Mada University, Anthropology Study Program. I always
                  prioritize a disciplined, honest, and responsible work ethic
                  in completing every job. I have honed my skills in content
                  writing,content creation, content planning,and event
                  coordination. I have several organizational experiences and
                  achievements, able to work individually or in a team, have a
                  passion for development, highly dedicated, highly motivated,
                  adaptable, and interested in learning new things.
                </p>
              ) : (
                <p
                  style={{
                    color: "white",
                    fontWeight: "400",
                    backgroundColor: "transparent",
                    fontSize: "0.85rem",
                    width: "120%",
                  }}
                >
                  I'm Yohana Novita Trisnawati, currently studying at Gadjah
                  Mada University, Anthropology Study Program. I always
                  prioritize a disciplined, honest, and responsible work ethic
                  in completing every job. I have honed my skills in content
                  writing,content creation, content planning,and event
                  coordination. I have several organizational experiences and
                  achievements, able to work individually or in a team, have a
                  passion for development, highly dedicated, highly motivated,
                  adaptable, and interested in learning new things.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 mb-3">
        <h3>Slogan/quotes?</h3>
      </div>
    </>
  );
};

export default About;
