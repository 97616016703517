import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import ContactItem from "../component/Contact/ContactItem";
const Contact = () => {
  return (
    <>
      <div
        className="container"
        style={{ padding: "3rem", textAlign: "justify" }}
      >
        <div className="row mt-3" style={{ width: "100%" }}>
          <div className="col" style={{ maxWidth: "480px" }}>
            <h1 style={{ fontSize: "4rem", fontWeight: "bold" }}>Let's Get </h1>
            <h1 style={{ fontSize: "4rem", fontWeight: "bold" }}>Started. </h1>
          </div>
          <div className="col" style={{ maxWidth: "480px" }}>
            <ContactItem
              title="Whatsapp"
              content="+6285877672307"
              link="https://wa.me/6285877672307?text=isi%20pesan%20default"
              icon={
                <FaWhatsapp
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "2rem",
                  }}
                />
              }
            />
          </div>
          <div className="col" style={{ maxWidth: "480px" }}>
            <ContactItem
              title="Email"
              content="novitayohana128@gmail.com "
              link="mailto:novitayohana128@gmail.com "
              icon={
                <FaEnvelope
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "2rem",
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
