import { FaTrophy } from "react-icons/fa";

const SkillItem = (props) => {
  return (
    <div
      style={{
        display: "flex",
        columnGap: "0.5rem",
        backgroundColor: "transparent",
      }}
    >
      <FaTrophy
        style={{
          backgroundColor: "transparent",
          fontWeight: "500",
          fontSize: "1.25rem",
          color: "hsl( 207 , 90% , 72% )",
        }}
      />
      <div style={{ backgroundColor: "transparent" }}>
        <p
          style={{
            fontSize: "1.25rem",
            fontWeight: "500",
            lineHeight: "1.25rem",
            marginBottom: "1rem",
            backgroundColor: "transparent",
          }}
        >
          {props.skill}
        </p>
      </div>
    </div>
  );
};
export default SkillItem;
